import illustration1 from "../static/illustrations/graphic_report.jpg";

import screenshot1 from "../static/mocks/slide01.png";
import screenshot2 from "../static/mocks/slide02.png";
import screenshot3 from "../static/mocks/slide03.png";
import screenshot4 from "../static/mocks/slide04.png";

export const sliderSection = {
  title: "Show me tendbes!",
};

export const sliderIllustrations = {
  description: "Tendbe delivers clear, AI-driven insights from your event data, helping you understand attendee preferences and behaviors. Use reports to fine-tune your business event and store offerings, increase engagement, and ensure every decision is data-backed for maximum impact.",
  images: [
    {
      src: illustration1,
      alt: "",
    },    
  ],
};

export const sliderScreenshots = {
  images: [
    {
      src: screenshot1,
      alt: "",
    },
    {
      src: screenshot2,
      alt: "",
    },
    {
      src: screenshot3,
      alt: "",
    },
    {
      src: screenshot4,
      alt: "",
    },
  ],
};
