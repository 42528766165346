import styled, { css } from "styled-components";
import { device, deviceMax } from "../../../utils/device";

export const FeaturesSection = styled.section(
  ({ theme }) => css`
    background: ${theme.colors.white};
    padding: 60px 0 50px;
  `
);

export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
  @media ${device.laptopL} {
    padding: 0;
  }
`;

export const FeatureTitle = styled.h2(
  ({ theme }) => css`
    color: ${theme.colors.primary[0]};
    font-family: ${theme.typography.fonts.secondary};
    font-size: ${theme.typography.sizes.extraExtraLarge};
    font-weight: ${theme.typography.weights.medium};
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 30px;
    line-height: 1.714;
  `
);

export const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  row-gap: 1.5rem;
  @media ${device.laptop} {
    grid-template-columns: 0.7fr 3fr 0.7fr;
    grid-template-rows: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
  }
`;

export const FeatureItem = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.primary["dark-90"]};
    text-align: left;
    padding: 0 0 1rem;
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 1rem;
    }

    & h3 {
      font-size: ${theme.typography.sizes.extraLarge};
      font-weight: ${theme.typography.weights.medium};
      margin: 0;
    }
    & img {
      width: 65px;
      height: 65px;
      margin-right: 1rem;
      margin-left: 0;
    }
    & p {
      line-height: 1.5;
      margin: 0;
    }

    @media screen and ${device.mobileM} and ${deviceMax.laptopMax} {
      &:nth-child(odd) {
        & div {
          justify-content: flex-start;
        }
      }
    }

    @media ${device.laptop} {
      &:nth-child(odd) {
        text-align: right;
      }

      &:nth-last-child(2) {
        text-align: center;
        padding: 0 500px;
        justify-content: center;
        align-items: center;
        display: flex;
        grid-column: 1 / 4;
      }

      & > div {
        display: block;
        margin: 0;
      }
      & img {
        margin: 0;
      }
      & h3 {
        margin: 10px 0 20px;
      }
    }
  `
);

export const FeatureImage = styled.div`
  grid-row: 4 / 5;
  width: 100%;
  display: none;

  & .cell-background {
    display: none;
  }
  & img {
    width: 100%;
  }
  @media ${device.laptop} {
    grid-row: 1 / -1;
    grid-column: 2 / 3;
    display: flex;
  }
`;
