import React from 'react'
import { useTranslation } from 'react-i18next';
import { pricing } from '../../../constants/pricing';
import { CardElement, CardWrapper, CardBorder, PricingContainer, PricingSubTitle, PricingTitle, PricingWrapper, Separator, CardTitle, DataContainer, AppLink, PricingGuy, PricingBarGradient, Button } from './Pricing.styled';
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";

const Pricing = () => {
    const { t } = useTranslation(); 
    const trans =  pricing(t);
    const tendbeApp = "https://app.beta.tendbe.com/login";

    return (
    <PricingWrapper id="pricing">
        <PricingContainer>
            <PricingTitle>{t("pricing.title")}</PricingTitle>
            <PricingSubTitle>{t("pricing.subtitle")}</PricingSubTitle>
            <CardWrapper>
               
                <CardBorder>
                    <CardElement>
                        <CardTitle>Free</CardTitle>
                        {trans && (
                            <DataContainer>
                                <ul>
                                    {trans.find(x => x.title === "free")?.list?.map((el, index) => 
                                    <li key={index}>
                                        {el.value ? (
                                            <FaCheck className="true" />
                                            ) : (
                                              <ImCross className="false" />
                                        )}
                                         {el.text}
                                    </li>
                                    )}
                                </ul>
                            </DataContainer>
                        )}
                        <AppLink href={tendbeApp}>Try Tendbe for free!</AppLink>
                    </CardElement>
                    <Separator></Separator>
                    <CardElement>
                        <PricingBarGradient />
                      
                        <CardTitle>Premium</CardTitle>
                        {trans && (
                            <DataContainer>
                                <ul>
                                    {trans.find(x => x.title === "premium")?.list?.map((el, index) => 
                                    <li key={index}>
                                        {el.value ? (
                                            <FaCheck className="true" />
                                            ) : (
                                              <ImCross className="false" />
                                        )}
                                         {el.text}
                                    </li>
                                    )}
                                </ul>
                            </DataContainer>
                        )}   
                        <Button href="mailto:business@tendbe.com" target="_blank">99€/Month (+ VAT)</Button>       
                    </CardElement>
                </CardBorder>
                <PricingGuy />
            </CardWrapper>           
        </PricingContainer>
    </PricingWrapper>
    );

}

export default Pricing;